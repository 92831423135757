<template>
    <div class="user-details">
        <header class="page-header">
            <h2>
                <router-link to="/users/">Users</router-link> <i class="fas fa-chevron-right"></i> User Details
            </h2>
            <div class="header-controls">
                <a @click.prevent="" class="button button-3">
                    <i class="fas fa-user-slash"></i> Delete user
                </a>
                <a class="button">
                    <i class="far fa-edit"></i> Reset password
                </a>
                <a class="button" 
                    @click.prevent="editing = !editing"
                    :class="{'open' : editing}"
                >
                    <i class="far fa-edit"></i> Edit user
                </a>
            </div>
        </header>
        <div class="content-wrapper">
            <user-details-form :record="record" :editing="editing" @stop-editing="editing = false"></user-details-form>
        </div>
    </div>
</template>

<script>

import UserModel from '../../models/UserModel.js'

import UserDetailsForm from '../../components/users/UserDetailsForm.vue';
import axios from 'axios';

export default {
    components: {
        UserDetailsForm
    },
    data: function(){

        return {
            model : new UserModel(),
            editing: false,
            record: this.$root.$data[UserModel.name][this.$route.params.id] = Object.hasOwnProperty.call(this.$root.$data[UserModel.name], this.$route.params.id) ? this.$root.$data[UserModel.name][this.$route.params.id] : new UserModel() 
        }
    },
    mounted: function(){
        console.log("mounted");
    },
    created: function(){
        console.log("created");
        if (!Object.hasOwnProperty.call(this.$root.$data[UserModel.name], this.$route.params.id)){
            this.$root.$data[UserModel.name][this.$route.params.id] = new UserModel();
        }
        axios.get('https://mockapi.starleasing.dev/users').then((response) => {
            this.$root.$data[UserModel.name].populate(response.data);
        }, (e) => {
            console.log(e);
            //!! need handler for errors
        });
    }
}
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";

</style>